@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

.subtitleFilters {
  padding: 25px 12px 12px 12px;
  margin-top: 16px;
}

.filter {
  margin-bottom: 1rem;
  .MuiCollapse-wrapperInner {
    .MuiList-root {
      max-height: 14rem;
      overflow: scroll;
      li.MuiListItem-root {
        padding-top: 3px;
        padding-bottom: 2px;
      }
    }
  }
  .checkboxType {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.topFilters {
  .MuiInputLabel-formControl {
    top: -6.25px;
  }
}

.loading {
  padding-top: 8rem;
  position: absolute;
  z-index: 30;
  background-color: #ffffff85;
  width: 100vw;
  height: 100vh;
  left: 0;
}

.pagination {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .textPagination {
    margin-right: 1.4rem;
  }
}

.cancelSearch {
  &:hover {
    color: #036fe2;
  }
}

.card {
  .media {
    height: 180px;
  }
  .contentCard {
    padding: 16px 16px 0 16px;
  }
  .description {
    height: 150px;
    overflow: scroll;
    font-size: 0.9rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  }
  .title {
    height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .titleDiv {
    display: flex;
  }
  .star {
    margin-top: 0.25rem;
    margin-left: 0.25rem;
  }
  .containerChips {
    padding: 0.5rem 1rem;
    height: 78px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    .chip {
      font-size: 0.65rem;
      margin: 0.5rem 0.5rem 0 0;
    }
  }
  .privacyDiv {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
  .greyIcon {
    color: rgba(44, 48, 50, 0.6);
    margin-right: 0.3rem;
  }
  .iconsContainer {
    flex-direction: column;
    align-items: unset;
    .MuiTooltip-popper {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
    }
  }
  .iconBug {
    color: #c1300b;
    margin-right: 0.3rem;
    a {
      text-decoration: none;
      color: #c1300b;
    }
    &:hover {
      background-color: #f9ebe7;
    }
  }
  .iconsShare {
    margin-left: 0.5rem;
  }
  .typeChip {
    margin: 0.5rem;
    position: absolute;
  }
}

.login {
  background-color: #e6edf4;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .container {
    background-color: #fff;
    border-radius: 10px;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    width: 400px;
    h4 {
      margin: 1rem 0 1.5rem;
    }
    h6 {
      margin-bottom: 1rem;
    }
    .title-google {
      margin: 2rem 0 1rem;
    }
    .input-login {
      margin-bottom: 1rem;
    }
    button {
      width: 100%;
    }
    .imgContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 200px;
      }
    }
    .forgot-password {
      align-self: flex-end;
      margin-top: 0.5rem;
      color: #1785fb;
      text-decoration: none;
      &:hover {
        color: #206fe2;
        text-decoration: underline;
      }
    }
    .back-login {
      margin-top: 2rem;
      color: #1785fb;
      font-weight: 600;
      text-decoration: none;
      &:hover {
        color: #206fe2;
        text-decoration: underline;
      }
    }
  }
}
